"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addExpandableClickListener = exports.trackExpandableClickEvent = void 0;
var utils_1 = require("./utils");
function trackMatomoEvent(event) {
    var matomo = window._paq || [];
    if (matomo !== undefined && matomo !== null) {
        matomo === null || matomo === void 0 ? void 0 : matomo.push(event);
    }
}
function trackExpandableClickEvent(eventCategory, eventAction, eventName) {
    trackMatomoEvent(["trackEvent", eventCategory, eventAction, eventName]);
}
exports.trackExpandableClickEvent = trackExpandableClickEvent;
function addExpandableClickListener(eventCategory, eventActionPrefix, eventNamePrefix) {
    var expandables = document.querySelectorAll(".js-expandables");
    expandables.forEach(function (expandableElement) {
        var expandablesTrackingName = getExpandablesTrackingName(expandableElement);
        var actionName = eventActionPrefix ? "".concat(eventActionPrefix, " ").concat(expandablesTrackingName) : expandablesTrackingName;
        var items = expandableElement.querySelectorAll('.expandable');
        items.forEach(function (li) {
            var clickElement = li.querySelector('.expandable__header');
            clickElement.addEventListener('click', function () {
                var expandableItemTrackingName = getExpandableItemTrackingName(clickElement);
                var eventName = eventNamePrefix ? "".concat(eventNamePrefix, " ").concat(expandableItemTrackingName) : expandableItemTrackingName;
                trackExpandableClickEvent(eventCategory, actionName, eventName);
            });
        });
    });
}
exports.addExpandableClickListener = addExpandableClickListener;
function getExpandablesTrackingName(expandable) {
    var _a, _b, _c, _d;
    var parentContainer = (_a = expandable.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement;
    var trackingName;
    trackingName = (_b = parentContainer.querySelector('.js-expandables-heading')) === null || _b === void 0 ? void 0 : _b.textContent;
    if (trackingName == undefined) {
        trackingName = (_d = (_c = parentContainer.parentElement) === null || _c === void 0 ? void 0 : _c.querySelector('h2')) === null || _d === void 0 ? void 0 : _d.textContent;
    }
    return (0, utils_1.removeTrailingWhitespace)(trackingName !== null && trackingName !== void 0 ? trackingName : "");
}
function getExpandableItemTrackingName(element) {
    return element.dataset.title;
}
